<template>
  <div>
    <img src="/img/topup/background@2x.png" alt="Topups.com Lifestyle" class="page-bg w-100 img-fluid">
    <div class="products container container-sm mb-3">
      <img
        :src="provider.LogoUrl.replace('png', 'svg')"
        :onerror="'this.src=' + provider.LogoUrl"
        class="card-img img-thumbnail py-4 px-2 ml-3 shadow"
        :alt="provider.Name"
      />
    </div>
    <div class="products container container-sm mb-3">
      <div class="card border-0 mb-md-3">
        <div class="card-body px-0 px-md-3">
          <h1
            class="card-title"
            v-if="!provider.meta.content.includes('<h1>')"
          >
            <b>
              <span class="text-capitalize" v-text="provider.Name"></span>
            </b>
          </h1>
          <div v-html="provider.meta.content"></div>
        </div>
      </div>
      <div class="row mb-4">
        <div
          v-for="(product,index) in products"
          :key="product.Maximum.Received"
          class="col-md-6"
          :class="{'mb-3': index != products.length - 1}"
          itemtype="http://schema.org/Product"
          itemscope
        >
          <meta itemprop="sku" :content="product.SkuCode" />
          <meta
            itemprop="name"
            :content="
              currency.symbol +
                product.Maximum.ReceiveValue +
                ' ' +
                provider.Name +
                ' topup'
            "
          />
          <div itemprop="brand" itemtype="http://schema.org/Brand" itemscope>
            <meta
              itemprop="name"
              :content="provider.Name"
              class="text-capitalize"
            />
            <meta itemprop="logo" :content="provider.LogoUrl" />
          </div>
          <meta itemprop="image" :href="provider.LogoUrl" />
          <meta itemprop="description" :content="provider.Name + ' topup of ' + currency.symbol + product.Maximum.ReceiveValue" />
          <div itemprop="offers" itemtype="http://schema.org/Offer" itemscope>
            <link itemprop="url" :href="'https://www.topups.com' + $route.path + '?sku=' + product.SkuCode" />
            <meta itemprop="availability" content="https://schema.org/InStock" />
            <meta itemprop="priceCurrency" :content="currency.name" />
            <meta itemprop="priceValidUntil" :content="expires" />
            <meta
              itemprop="price"
              :content="product.Maximum.ReceiveValue | currency"
            />
            <div
              itemprop="seller"
              itemtype="http://schema.org/Organization"
              itemscope
            >
              <meta itemprop="name" content="topups.com" />
            </div>
          </div>
          <div class="card rounded-xl" :id="product.SkuCode">
            <div class="card-body p-3 d-flex align-items-center">
              <img
                :src="provider.LogoUrl.replace('png', 'svg')"
                :onerror="'this.src=' + provider.LogoUrl"
                class="card-img img-thumbnail pr-4 border-0"
                :alt="provider.Name"
              />
              <p class="mb-0">
                Receiver gets:
                <span class="h2 d-block"><b>{{ currency.symbol }}{{ product.Maximum.ReceiveValue | currency }} {{ currency.iso }}</b></span>
              </p>
            </div>
            <div class="card-body p-3 border-top">
              <div class="row">
                <p class="mb-0 col-6">
                  Order now
                  <span class="h3 d-block" v-if="product.Checkout"><b>{{ product.Checkout.currency.symbol }}{{ product.Maximum.SendValue*rate | currency }} {{ product.Checkout.currency.iso }}</b></span>
                  <span class="h3 d-block" v-else><b>{{ currency.symbol }}{{ product.Maximum.ReceiveValue | currency }} {{ currency.iso }}</b></span>
                </p>
                <div class="col-6">
                  <button
                    type="button"
                    class="btn btn-lg btn-block h-100 text-white btn-success"
                    @click="nextStep(product)"
                  >
                    <b>Order</b> 
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div itemscope itemtype="http://schema.org/FAQPage" class="bg-secondary py-3 container container-sm" v-if="faqs.length">
      <h5 class="text-center mb-3 col-md-12"><b>FAQ</b></h5>
      <Faq
        v-for="(faq, index) in faqs"
        :faq="faq"
        :key="index"
        class="col-md-6 mb-md-3"
        :class="{ 'mb-3': index != faqs.length - 1 }"
      />
    </div>
    <div class="py-3 container container-sm" v-if="country.CountryIso">
      <h5 class="text-center mb-3 col-md-12"><b>{{ country.CountryName }} networks</b></h5>
      <Networks :country="country" />
    </div>
    <div
      class="details position-fixed d-flex flex-column justify-content-end h-100 w-100"
      @click.self="showDetails = false"
      :class="{ show: showDetails }"
    >
      <ValidationObserver v-slot="{ handleSubmit }" slim>
        <div class="bg-white w-100 p-3 pb-5">
          <p class="text-center" v-if="selectedProduct.length">
            <b
              >Send {{ currency.symbol
              }}{{ selectedProduct.Maximum.ReceiveValue | currency }} top-up
              to:</b
            >
          </p>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">+{{ prefix }}</span>
            </div>
            <input
              type="tel"
              class="form-control"
              v-model="mobileNumber"
              placeholder="Type mobile number"
              aria-label="Type mobile number"
              ref="target"
            />
          </div>
          <ValidationProvider
            name="Mobile Number"
            :rules="{ required: true, regex: regex }"
            v-slot="{ errors }"
            slim
          >
            <div class="mb-3">
              <input type="tel" v-model="internationalNumber" hidden />
              <small class="form-text text-danger w-100">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <button
            class="btn btn-outline-primary btn-block"
            @click="readContacts"
            v-if="contactSupport"
          >
            Add contact from address book
          </button>
          <button
            class="btn btn-success btn-block btn-lg text-white"
            :disabled="!mobileNumber.length"
            @click.prevent="handleSubmit(goToCheckout)"
          >
            <b>Continue to checkout</b>
          </button>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import { mapGetters } from "vuex";
import { extend } from "vee-validate";
import { regex } from "vee-validate/dist/rules";

import Faq from "@/components/Faq.vue";
import Networks from "@/components/Networks.vue";

extend("regex", regex);

export default {
  data() {
    return {
      provider: {},
      products: [],
      currency: {},
      faqs: [],
      regex: null,
      prefix: "",
      rate: null,
      mobileNumber: "",
      internationalNumber: "",
      showDetails: false,
      selectedProduct: [],
      contactSupport: true,
      expires: moment().add(1, 'y').format('YYYY-MM-DD')
    };
  },
  filters: {
    currency(val) {
      return Math.abs(val).toFixed(2);
    }
  },
  watch: {
    mobileNumber(newValue) {
      this.internationalNumber = this.prefix + newValue;
    },
    "$route.params.products"() {
      if (this.$route.params.products == undefined) {
        return this.$router.replace({path: '/'});
      }
      this.products = this.$route.params.products;
      this.provider = this.$route.params.provider;
      this.UatNumber = this.$route.params.UatNumber;
      this.currency = this.$route.params.currency;
      this.rate = this.$route.params.rate;
      this.$emit('updateHead');
      this.getFaqs();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  },
  beforeMount() {
    if (this.$route.params.products == undefined) {
      return this.$router.replace({path: '/'});
    }

    this.products = this.$route.params.products;
    this.provider = this.$route.params.provider;
    this.UatNumber = this.$route.params.UatNumber;
    this.currency = this.$route.params.currency;
    this.rate = this.$route.params.rate;
    this.getFaqs();
    if (this.$route.query.sku != undefined) {
      let index = this.products.map(function(e) { return e.SkuCode; }).indexOf(this.$route.query.sku);
      let queried = this.products[index];
      this.products.splice(index,1);
      this.products.unshift(queried);
    }
    if (!navigator.contacts) return (this.contactSupport = false);
  },
  head: {
    title() {
      return {
        inner: this.provider.meta.title
      }
    },
    meta() {
      return [
        { n: 'description', c: this.provider.meta.description, id: 'desc' }, // id to replace intead of create element
        // Twitter
        { n: 'twitter:title', c: this.provider.meta.title },
        { n: 'twitter:description', c: this.provider.meta.description},
        // Google+ / Schema.org
        // { ip: 'name', c: this.provider.meta.title },
        // { ip: 'description', c: this.provider.meta.description },
        // Facebook / Open Graph
        // { p: 'fb:app_id', c: '123456789' },
        { p: 'og:title', c: this.provider.meta.title },
        // { p: 'og:image', c: 'https://example.com/image.jpg' },
      ]
    },
    link() {
      return [
        {
          rel: "canonical",
          href: "https://www.topups.com" + this.$route.path,
          id: "canonical"
        }
      ];
    }
  },
  methods: {
    getFaqs() {
      this.$http({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}faqs?network=${this.provider.ProviderCode}`
      }).then(
        result => {
          let faqs = result.data.faqs;
          this.faqs = faqs;
        },
        error => {
          console.error(error);
        }
      );
    },
    nextStep(product) {
      this.selectedProduct = product;
      this.prefix = this.selectedProduct.UatNumber.slice(
        0,
        this.selectedProduct.UatNumber.indexOf("0")
      );
      this.showDetails = true;

      let regex = this.provider.ValidationRegex;
      this.regex = regex;

      this.$refs.target.focus();
    },
    readContacts() {
      const api = navigator.contacts;

      if (api && !!api.select) {
        // new Chrome API
        api
          .select(["name", "tel"], { multiple: false })
          .then(contacts => {
            if (contacts.length) {
              let number = contacts[0].tel[0];
              if (number.includes("+" + this.prefix)) {
                this.mobileNumber = number
                  .slice(this.prefix.length + 1)
                  .replace(/ +/g, "");
              } else {
                this.mobileNumber = number.slice(1).replace(/ +/g, "");
              }
            }
          })
          .catch(err => {
            console.error("Fetching contacts failed: " + err.name);
          });
      } else {
        console.error("Contacts API not supported.");
      }
    },
    goToCheckout() {
      this.$http({
        method: "post",
        url: `${process.env.VUE_APP_API_URL}order/add-edit`,
        data: {
          id: this.order.id,
          order: {
            sender: this.user.data ? this.user.data[0].email : null,
            receiver: {
              number: this.prefix + this.mobileNumber,
              product: this.selectedProduct,
              network: this.provider,
              currency: this.currency.iso,
              value: this.selectedProduct.Maximum.SendValue,
              method: this.selectedProduct.RedemptionMechanism
            }
          }
        }
      }).then(
        result => {
          this.$store.dispatch("fetchOrder", result.data.id);
          if (this.user.data == null) {
            this.$router.push({ name: "Login" });
          } else {
            this.$router.push({ name: "Checkout" });
          }
        },
        error => {
          console.error(error);
        }
      );
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      order: "order",
      country: "country"
    })
  },
  components: {
    Faq,
    Networks,
  }
};
</script>

<style lang="scss" modular>
.page-bg {
  height: 30vw;
  object-fit: cover;
  object-position: center top;
  @media screen and (min-width: 576px) {
    height: 25vw;
  }
  & +.container {
    margin-top: -15vw;
    @media screen and (min-width: 576px) {
      margin-top: -6.6vw;
    }
  }
}

.card-body h1 {
  font-size: 1.75rem;
}
.products .card-img {
  max-height: 100px;
  max-width: 100px;
  object-fit: contain;
}
.details {
  left: 0;
  bottom: 0;
  opacity: 1;
  z-index: 1;
  background-color: rgba(#000000, 0.5);
  transition: all 0.2s ease-in-out;
  > div {
    transform: translateY(0);
    border-radius: 1rem 1rem 0 0;
    transition: transform 0.2s ease-in-out;
  }
  &:not(.show) {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    > div {
      transform: translateY(100%);
    }
  }
}
</style>
